import paint1 from './Paint1.jpg';
import paint2 from './Paint2.jpg';
import paint3 from './Paint3.jpg';
import paint4 from './Paint4.jpg';
import paint5 from './Paint5.jpg';
import paint6 from './Paint6.jpg';
import paint7 from './Paint7.jpg';
import paint8 from './Paint8.jpg';
import paint9 from './Paint9.jpg';
import paint10 from './Paint10.jpg';
import paint11 from './Paint11.jpg';
import paint12 from './Paint12.jpg';
import paint13 from './Paint13.jpg';
import paint14 from './Paint14.jpg';

export const paintings = [
  { src: paint1, width: 1066, height: 781, title: 'Painting 1' },
  { src: paint2, width: 1297, height: 895, title: 'Painting 2' },
  { src: paint3, width: 1069, height: 807, title: 'Painting 3' },
  { src: paint4, width: 1044, height: 772, title: 'Painting 4' },
  { src: paint5, width: 1067, height: 860, title: 'Painting 5' },
  { src: paint6, width: 1120, height: 908, title: 'Painting 6' },
  { src: paint7, width: 1331, height: 921, title: 'Painting 7' },
  { src: paint8, width: 1343, height: 950, title: 'Painting 8' },
  { src: paint9, width: 1388, height: 919, title: 'Painting 9' },
  { src: paint10, width: 1101, height: 769, title: 'Painting 10' },
  { src: paint11, width: 1084, height: 855, title: 'Painting 11' },
  { src: paint12, width: 817, height: 967, title: 'Painting 12' },
  { src: paint13, width: 1319, height: 980, title: 'Painting 13' },
  { src: paint14, width: 1323, height: 923, title: 'Painting 14' },
];
