import React from 'react';
import { Header } from '../../components/Header';
import { ImageGallery } from '../../components/ImageGallery';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  margin: 30px;
  width: calc(100% - 60px);
  min-height: calc(100vh - 60px);
  padding: 20px 70px;
  box-sizing: border-box;
  display: inline-block;
  background-color: #f5f5f5;
  @media (max-width: 1000px) {
    padding: 20px 30px;
  }
`;

export default function HomeComponent() {
  return (
    <Container>
      <Header />
      <ImageGallery />
    </Container>
  );
}
