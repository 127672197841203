import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

const Title = styled.h1`
  font-weight: normal;
  font-size: 50px;
  margin: 0;
  padding: 0 0 0 25px;
  border-bottom: 1px solid black;
  display: inline-block;
`;

const Contact = styled.p`
  margin: 8px 0 20px;
  padding: 0 0 0 25px;
`;

const StyledLink = styled.a`
  text-decoration: none;
  border-bottom: 1px solid #828282;
  color: #828282;
`;

export default function HeaderComponent() {
  return (
    <Container>
      <Title>Art By Kao</Title>
      <Contact>
        <b>Contact:</b> artbykao@gmail.com | <b>Instagram:</b>{' '}
        <StyledLink href="https://www.instagram.com/artbykao" target="_blank">
          @ArtByKao
        </StyledLink>
      </Contact>
    </Container>
  );
}
