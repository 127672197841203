import Home from './pages/home';
import './App.css';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <>
      <Helmet>
        <title>Art By Kao</title>
      </Helmet>
      <Home />
    </>
  );
}

export default App;
